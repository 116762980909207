@use '../variables' as var

=heading($isNoMargin: false)
	color: var.$color-surface-light-text-primary
	font-family: var(--font-heading)
	line-height: 0.96
	font-weight: 700

	@if $isNoMargin
		margin: 0

=h1($isNoMargin: false)
	+heading($isNoMargin)
	+h2

	@media (min-width: var.$break40)
		font-size: 4rem
		letter-spacing: -0.12rem

=h2($isNoMargin: false)
	// @TODO: mobile
	+heading($isNoMargin)

	font-size: 2.5rem
	letter-spacing: -0.075rem

// @TODO: h3, h4, h5
