@use 'common'
@use 'sass:color'
@import 'react-toastify/dist/ReactToastify.css'
@import 'react-ripple-click/dist/index.css'
@import 'collapsible-react-component/dist/index.css'

:root
	--homepage-hero-offset-button: 18svw
	--hero-content-offset: 3svh
	--header-height: 5.3125rem
	--common-edge-gap: 1.875rem
	--common-content-width-small: #{common.$contentWidth-small}
	--common-content-width-default: #{common.$contentWidth-default}
	--common-content-width-wide: #{common.$contentWidth-wide}
	--common-content-gap: 7.5rem
	// @TODO: add gaps to the header
	--common-header-top-gap: 1.375rem
	--common-header-bottom-gap: 4rem
	overflow-y: scroll
	--waitlistButton-height: 2.625rem
	--waitlistButton-offsetTop: var(--header-height)

	// https://www.npmjs.com/package/shared-loading-indicator
	--ProgressLoadingIndicator-color: #{common.$primary-color}

	@media (min-width: common.$break40)
		--common-edge-gap: 2rem
		--common-content-gap: 11.25rem

	@media (min-width: common.$break48)
		--homepage-hero-offset-button: 16svh

	@media (min-width: common.$break100)
		--homepage-hero-offset-button: 16svh

body
	margin: 0
	font-family: var(--font-body)
	background-color: common.$color-background
	color: common.$color-surface-light-text-secondary
	-webkit-tap-highlight-color: transparent

*, *::before, *::after
	box-sizing: border-box

h1
	+common.h1

h2
	+common.h2

// @TODO: h3, h4, h5

button
	cursor: pointer

em
	font-style: normal
	color: common.$color-surface-light-border-primary-hover

a
	font-weight: 600
	color: common.$primary-color
	transition: color 0.2s

	&:hover, &:focus-visible
		color: color.adjust(common.$primary-color, $lightness: 15%)
