$color-surface-light-primary-default: #FCFCF9
$color-surface-light-secondary-default: #F5F3F0
$color-surface-light-tertiary-default: #F0EEEA
$color-surface-light-border-primary-default: #C2BCB2
$color-surface-light-border-primary-hover: #59544B
$color-surface-light-border-primary-active: #2F2A24
$color-surface-light-border-tertiary: #ECE2D6
$color-surface-light-text-primary: #2D2A25
$color-surface-light-text-secondary: #59544B
$color-surface-light-text-tertiary: #A29B90
$color-background: #F9F6F2

$color-kurmu-50: #FDFDFC

$color-green-500: #00ED92

$color-grayscale-0: #FFFFFF
$color-grayscale-900: #4F4F4F
$color-grayscale-950: #1D1D1D

$primary-color: $color-green-500

$color-text-secondary: #4B4741
$color-text-tertiary: #918B81

$break25: 25rem
$break30: 30rem
$break35: 35rem
$break40: 40rem
$break48: 48rem
$break62: 62rem
$break64: 64rem
$break75: 75rem
$break87: 87rem
$break100: 100rem
$break125: 128rem

$contentWidth-small: 40.625rem
$contentWidth-default: 82.5rem
$contentWidth-wide: 87.5rem
